import { getCurrentUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { StopListenerCallback } from '@aws-amplify/core/dist/esm/Hub/types';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@capacitor/status-bar';
import { Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, Suspense, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import Container from './common/Container';
import Loading from './common/Loading';
import Logo from './common/Logo';
import Navigation from './common/Navigation';
import NavigationMobile from './common/NavigationMobile';
import Toasts from './common/toasts/Toasts';
import UserNavigation from './common/UserNavigation';
import UserNavigationMobile from './common/UserNavigationMobile';
import LoggedOut from './components/logged-out/LoggedOut';
import Survey from './components/logged-out/Survey';
import UserInfo from './components/UserInfo';
import { ToastProvider } from './contexts/toast.context';
import Advice from './pages/Advice';
import Advices from './pages/Advices';
import Clinic from './pages/Clinic';
import Condition from './pages/Condition';
import Conditions from './pages/Conditions';
import Exercise from './pages/Exercise';
import Exercises from './pages/Exercises';
import { BottomBanner } from '@chiroup/components';

const queryClient = new QueryClient();

export function App() {
  const [authState, setAuthState] = useState<string>();
  const [user, setUser] = useState<any>();
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    let isDestroyed = false;
    let stopListener: StopListenerCallback;
    const doIt = async () => {
      const currentUser = await getCurrentUser().catch((err) => {
        console.error({ err });
      });
      if (currentUser && !isDestroyed) {
        setAuthState('authenticated');
        setUser(currentUser);
      }
      stopListener = Hub.listen('auth', (data) => {
        if (!isDestroyed) {
          if (data.payload.event === 'tokenRefresh_failure') {
            setAuthState('signedOut');
            // setInactivityModalOpen(true);
          } else if (data.payload.event === 'signedOut') {
            setAuthState('signedOut');
          } else if (data.payload.event === 'signedIn') {
            setAuthState('authenticated');
            setUser(data.payload.data);
          }
        }
      });
    };
    doIt();
    return () => {
      isDestroyed = true;
      stopListener?.();
    };
  }, []);

  useEffect(() => {
    const doIt = async () => {
      await SplashScreen.hide();
    };
    doIt();
  }, []);

  useEffect(() => {
    const platform = Capacitor.getPlatform();
    const isIos = platform === 'ios';
    const doIt = async () => {
      const statusBarInfo = await StatusBar.getInfo();
      const isVisible = statusBarInfo.visible;
      if (scrollPosition && isVisible) {
        await hideStatusBar();
      } else if (!isVisible && !scrollPosition) {
        await showStatusBar();
      }
    };
    if (isIos) {
      doIt();
    }
  }, [scrollPosition]);

  const hideStatusBar = async () => {
    await StatusBar.hide();
  };

  const showStatusBar = async () => {
    await StatusBar.show();
  };

  return (
    <div className="bg-gray-50">
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <>
            {authState === 'authenticated' && user ? (
              <Container>
                <div className="min-h-full">
                  <header className="bg-white border-b border-gray-100">
                    <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                      <Popover className="flex justify-between h-20 w-full">
                        <div className="flex justify-between w-full mr-4">
                          <div className="flex px-2 lg:px-0">
                            <div className="flex-shrink-0 flex items-center">
                              <Logo />
                            </div>
                            <Navigation />
                          </div>
                          <div className="flex items-center lg:hidden">
                            <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset">
                              <span className="sr-only">Open main menu</span>
                              <Bars3Icon
                                className="block h-6 w-6"
                                aria-hidden="true"
                              />
                            </Popover.Button>
                          </div>
                        </div>
                        <Transition.Root as={Fragment}>
                          <div className="lg:hidden">
                            <Transition.Child
                              as={Fragment}
                              enter="duration-150 ease-out"
                              enterFrom="opacity-0"
                              enterTo="opacity-100"
                              leave="duration-150 ease-in"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Popover.Overlay
                                className="z-20 fixed inset-0 bg-black bg-opacity-25"
                                aria-hidden="true"
                              />
                            </Transition.Child>
                            <Transition.Child
                              as={Fragment}
                              enter="duration-150 ease-out"
                              enterFrom="opacity-0 scale-95"
                              enterTo="opacity-100 scale-100"
                              leave="duration-150 ease-in"
                              leaveFrom="opacity-100 scale-100"
                              leaveTo="opacity-0 scale-95"
                            >
                              <Popover.Panel
                                className="z-30 absolute top-0 right-0 max-w-none w-full p-2 transition transform origin-top px-4 pt-4"
                                // style={{
                                //   margin: `env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)`,
                                // }}
                              >
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-300">
                                  <div className="pt-3 pb-2">
                                    <div className="flex items-center justify-between px-4">
                                      <div>
                                        <Popover.Button as={Link} to="/">
                                          <Logo />
                                        </Popover.Button>
                                      </div>
                                      <div className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset">
                                          <span className="sr-only">
                                            Close menu
                                          </span>
                                          <XMarkIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                          />
                                        </Popover.Button>
                                      </div>
                                    </div>
                                    <NavigationMobile />
                                  </div>
                                  <div className="pt-4 pb-2">
                                    <UserInfo />
                                    <UserNavigation />
                                  </div>
                                </div>
                              </Popover.Panel>
                            </Transition.Child>
                          </div>
                        </Transition.Root>
                        <UserNavigationMobile />
                      </Popover>
                    </div>
                  </header>
                  <Routes>
                    <Route path="/clinic" element={<Clinic />} />
                    <Route path="/advice/:adviceId" element={<Advice />} />
                    <Route path="/advice" element={<Advices />} />
                    <Route
                      path="/conditions/:conditionId"
                      element={<Condition />}
                    />
                    <Route path="/conditions" element={<Conditions />} />
                    <Route
                      path="/exercises/:exerciseId"
                      element={<Exercise />}
                    />
                    <Route path="/exercises" element={<Exercises />} />
                    <Route path="/survey" element={<Survey />} />
                    <Route path="/" element={<Exercises />} />
                    <Route
                      path="/login"
                      element={<Navigate to="/" replace />}
                    />
                  </Routes>
                </div>
              </Container>
            ) : (
              <Suspense fallback={<Loading />}>
                <div className="h-full flex flex-col">
                  <div style={{ minHeight: 'calc(100vh - 100px)' }}>
                    <LoggedOut setAuthState={setAuthState} setUser={setUser} />
                  </div>
                </div>
              </Suspense>
            )}
            <Toasts />
          </>
        </ToastProvider>
        {import.meta.env.VITE_STAGE === 'sandbox' && (
          <BottomBanner message="You are in sandbox mode. Do not use real data." />
        )}
      </QueryClientProvider>
    </div>
  );
}

export default App;

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return scrollPosition;
};
